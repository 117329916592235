<script setup lang="ts">
const router = useRouter()
const onClickLeft = () => history.back()
function useCard() {
  console.log('使用身份卡找回')
  // router.push('/id-card-find')
}
function usePhone() {
  router.push('/usePhone')
}
function goService() {
  router.push('/service')
}
</script>

<template>
  <div h-screen>
    <van-nav-bar title="更换账号" left-arrow @click-left="onClickLeft" />
    <van-cell title="使用身份卡找回" is-link mt-3 h-13 @click="useCard()" />
    <van-cell title="使用手机找回" is-link h-13 @click="usePhone()" />
    <van-cell title="使用客服找回" is-link h-13 @click="goService()" />
    <div mt-4 px-4 text-xs ct-e8e8e8>
      <p mb-3>如果您的账号丢失，可以使用以下方式找回：</p>
      <p leading-4 ct-e8e8e899>1、使用您已保存的身份卡。</p>
      <p leading-4 ct-e8e8e899>2、输入您已经绑定的手机号，接收验证码找回。</p>
      <p leading-4 ct-e8e8e899>3、联系在线客服。</p>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-cell) {
  font-size: 16px;
  background: #111425;
  color: #e8e8e8;
  border-bottom: 1px solid #41466040;
}
:deep(.van-cell:after) {
  border: none;
}
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-cell-group--inset) {
  border-radius: unset;
}
:deep(.van-field__label) {
  color: #e8e8e8;
}
:deep(.van-cell__value) {
  color: #5c5e69;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
</style>
